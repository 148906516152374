import s from "./Main.module.css";
import NewsCarousel from "../../Components/Main/NewsCarousel";
import EventsBlock from "../../Components/Main/EventsBlock";
import NewsBlock from "../../Components/Main/NewsBlock";
import FlagsBlock from "../../Components/Main/FlagsBlock";
import { useEffect, useState } from "react";
import PartnersBlock from "../../Components/About/PartnersBlock";
import { useSelector } from "react-redux";
import { MiniHistoryBlock } from "../../Components/About/HistoryBlock";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import IdeaModal from "../../Components/Footer/IdeaModal";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { lang } = useSelector((state) => state.lang);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={s.main}>
      <NewsCarousel />
      <div className={s.infoBlocks}>
        <div className={s.infoBlock}>
          <h3>{lang === "ru" ? "Присоединяйтесь к нам!" : "Join us!"}</h3>
          <p>
            {lang === "ru"
              ? "Станьте частью нашей команды или предложите свою идею"
              : "Become part of our team or suggest your idea"}
          </p>
          <Button onClick={() => setIsModalOpen(true)}>
            {lang === "ru" ? "Подать заявку" : "Submit application"}
          </Button>
        </div>
        <div className={s.infoBlock}>
          <h3>{lang === "ru" ? "Наши проекты" : "Our projects"}</h3>
          <p>
            {lang === "ru"
              ? "Узнайте больше о текущих проектах БРИКС"
              : "Learn more about current BRICS projects"}
          </p>
          <NavLink to="/about">
            <Button>{lang === "ru" ? "Подробнее" : "Learn more"}</Button>
          </NavLink>
        </div>
      </div>
      <EventsBlock />
      <NewsBlock />
      <FlagsBlock />
      {/* <VideoPlayer videoId={1} /> */}
      <MiniHistoryBlock lang={lang} />
      <PartnersBlock lang={lang} />

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={600}
        className={s.ideaModal}
      >
        <IdeaModal
          setIsModalOpen={setIsModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Main;
