import s from "./Header.module.css";
import logo from "../../assets/Header/logo+.svg";
import { Button, ConfigProvider, Menu, Modal, Select } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { LoginOutlined, UserOutlined } from "@ant-design/icons";
import { setEnLanguage, setRuLanguage } from "../../Redux/language/langActions";
import { useDispatch, useSelector } from "react-redux";
import { HeaderThemes } from "../../themes/HeaderThemes";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import logo1 from "../../assets/Header/LoginLogo.svg";
import LoginModal from "../LoginModal";
import { resetReg } from "../../Redux/auth/authActions";
import axios from "axios";
import Marquee from "react-fast-marquee";

const Header = () => {
  const { lang } = useSelector((state) => state.lang);
  return (
    <header className={s.header}>
      <div className={s.top}>
        <LeftPart lang={lang} />
        <RightPart lang={lang} />
      </div>
      <NavBar lang={lang} />
      <Ticker />
    </header>
  );
};
const LeftPart = ({ lang }) => {
  const isSmall = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <div className={s.left}>
      <NavLink to="/">
        <img src={logo} alt="brics" />
      </NavLink>
      {!isSmall && (
        <article>
          {lang === "ru"
            ? "Коммуникационная молодежная площадка"
            : "Youth communication platform"}
        </article>
      )}
    </div>
  );
};
const RightPart = ({ lang }) => {
  return (
    <div className={s.right}>
      <Languages lang={lang} />
      <LoginButton lang={lang} />
    </div>
  );
};
const Languages = ({ lang }) => {
  const dispatch = useDispatch();

  const handleChange = (value) => {
    const selectedLang = value;
    if (selectedLang === "ru") {
      dispatch(setRuLanguage());
    } else if (selectedLang === "en") {
      dispatch(setEnLanguage());
    }
  };
  const options = [
    { value: "ru", label: "RU" },
    { value: "en", label: "EN" },
  ];

  return (
    <ConfigProvider theme={HeaderThemes.languages}>
      <Select
        style={{
          width: 100,
          height: 52,
          textAlign: "center",
        }}
        defaultValue={
          lang === "ru"
            ? {
                value: "ru",
                label: "RU",
              }
            : {
                value: "en",
                label: "EN",
              }
        }
        onChange={handleChange}
        options={options}
      />
    </ConfigProvider>
  );
};

const LoginButton = ({ lang }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { username } = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1201px)" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const onCancel = () => {
    setIsModalOpen(false);
    dispatch(resetReg());
  };
  return (
    <span>
      {isMobile && (
        <ConfigProvider theme={HeaderThemes.loginMobile}>
          {isAuthenticated ? (
            <NavLink to="/cabinet">
              <Button
                style={{ width: "52px", height: "52px" }}
                icon={<UserOutlined />}
              ></Button>
            </NavLink>
          ) : (
            <div>
              <Button
                onClick={showModal}
                style={{ width: "52px", height: "52px" }}
                icon={<LoginOutlined />}
              ></Button>
            </div>
          )}
        </ConfigProvider>
      )}
      {isDesktop && (
        <ConfigProvider theme={HeaderThemes.login}>
          {isAuthenticated ? (
            <NavLink to="/cabinet">
              <Button style={{ height: "52px" }} icon={<UserOutlined />}>
                {username}
              </Button>
            </NavLink>
          ) : (
            <div style={{ minWidth: "250px" }}>
              <Button
                onClick={showModal}
                style={{ height: "52px" }}
                icon={<LoginOutlined />}
              >
                {lang === "ru"
                  ? "Стать участником проекта"
                  : "Become a project participant"}
              </Button>
            </div>
          )}
        </ConfigProvider>
      )}
      <Modal
        footer={() => <></>}
        title={<img src={logo1} alt="" />}
        open={isModalOpen}
        onCancel={onCancel}
      >
        <LoginModal onCancel={onCancel} setIsModalOpen={setIsModalOpen} />
      </Modal>
    </span>
  );
};

const NavBar = ({ lang }) => {
  const location = useLocation();

  const items = [
    {
      key: "/",
      label: <NavLink to="/">Главная</NavLink>,
    },
    {
      key: "/news",
      label: <NavLink to="/news">Новости</NavLink>,
    },
    {
      key: "/announcements",
      label: <NavLink to="/announcements">Анонсы</NavLink>,
    },
    {
      key: "/sport",
      label: <NavLink to="/sport">Спорт</NavLink>,
    },
    {
      key: "/science",
      label: <NavLink to="/science">Наука</NavLink>,
    },
    {
      key: "/chatroom",
      label: <NavLink to="/chatroom">Форум</NavLink>,
    },
    {
      key: "/gallery",
      label: <NavLink to="/gallery">Галерея</NavLink>,
      children: [
        {
          key: "/gallery/photo",
          label: <NavLink to="/gallery/photo">Фотобанк</NavLink>,
        },
        {
          key: "/gallery/video",
          label: <NavLink to="/gallery/video">Видео</NavLink>,
        },
      ],
    },
    {
      key: "/about",
      label: <NavLink to="/about">О проекте</NavLink>,
    },
  ];

  const itemsEn = [
    {
      key: "/",
      label: <NavLink to="/">Main</NavLink>,
    },
    {
      key: "/news",
      label: <NavLink to="/news">News</NavLink>,
    },
    {
      key: "/announcements",
      label: <NavLink to="/announcements">Announcements</NavLink>,
    },
    {
      key: "/sport",
      label: <NavLink to="/sport">Sport</NavLink>,
    },
    {
      key: "/science",
      label: <NavLink to="/science">Science</NavLink>,
    },
    {
      key: "/chatroom",
      label: <NavLink to="/chatroom">Forum</NavLink>,
    },
    {
      key: "/gallery",
      label: <NavLink to="/gallery">Gallery</NavLink>,
      children: [
        {
          key: "/gallery/photo",
          label: <NavLink to="/gallery/photo">Photo</NavLink>,
        },
        {
          key: "/gallery/video",
          label: <NavLink to="/gallery/video">Video</NavLink>,
        },
      ],
    },
    {
      key: "/about",
      label: <NavLink to="/about">About the project</NavLink>,
    },
  ];

  const currentItems = lang === "ru" ? items : itemsEn;
  const getSelectedKey = (pathname) => {
    if (pathname.startsWith("/news")) return "/news";
    if (pathname.startsWith("/announcements")) return "/announcements";
    if (pathname.startsWith("/gallery")) return "/gallery";
    return pathname;
  };
  return (
    <div className={s.nav}>
      <ConfigProvider theme={HeaderThemes.nav}>
        <Menu
          className="custom-submenu-title"
          style={{ justifyContent: "center" }}
          mode="horizontal"
          selectedKeys={[getSelectedKey(location.pathname)]}
          items={currentItems}
        />
      </ConfigProvider>
    </div>
  );
};

const Ticker = () => {
  const [data, setData] = useState("");
  const [dataW, setDataW] = useState("");
  const capitals = [
    // БРИКС
    { country: "Russia", city: "Moscow", currency: "RUB" },
    { country: "Brazil", city: "Brasilia", currency: "BRL" },
    { country: "India", city: "New Delhi", currency: "INR" },
    { country: "China", city: "Beijing", currency: "CNY" },
    { country: "South Africa", city: "Pretoria", currency: "ZAR" },
    // БРИКС+
    { country: "Egypt", city: "Cairo", currency: "EGP" },
    { country: "Ethiopia", city: "Addis Ababa", currency: "ETB" },
    { country: "Iran", city: "Tehran", currency: "IRR" },
    { country: "UAE", city: "Abu Dhabi", currency: "AED" },
    { country: "Saudi Arabia", city: "Riyadh", currency: "SAR" },
    { country: "Argentina", city: "Buenos Aires", currency: "ARS" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currencyRes = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/USD"
        );
        const rates = currencyRes.data.rates;

        const weatherPromises = capitals.map(async ({ city, country }) => {
          const weatherRes = await axios.get(
            `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=efa89434c876cd0cf42525688971bc15`
          );
          return `${city}: ${
            weatherRes.data.weather[0].description
          }, ${Math.floor(weatherRes.data.main.temp - 273)}°C`;
        });

        const weatherData = await Promise.all(weatherPromises);
        setDataW(weatherData.join(" ┊ "));

        // Формируем строку с курсами валют
        const combinedData = capitals
          .map(({ currency }, index) => {
            const currencyRate = rates[currency]?.toFixed(2) || "N/A";
            return `${currency}/USD ${currencyRate}`;
          })
          .join(" ┊ ");

        setData(combinedData);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
        setData("Ошибка загрузки данных...");
      }
    };

    fetchData();
  }, []);

  return (
    <div className={s.tickerContainer}>
      <div className={s.shadow}>
        <div className={s.weather}>
          <Marquee velocity={20} resetAfterTries={50}>
            {dataW}
          </Marquee>
        </div>
      </div>
      <div className={s.shadow}>
        <div className={s.currency}>
          <Marquee velocity={20} resetAfterTries={50}>
            {data}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Header;
